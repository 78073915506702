import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import { ClubNight } from '../components/data/clubNight';
import { IClubNight } from '../typings';
import { Artist } from '../components/data/artist';

import './clubNight.scss'

interface IProps {
  data: { clubNight: IClubNight };
}

const ClubNightPage: React.FunctionComponent<IProps> = props => {
  const { data } = props;
  const { clubNight } = data;
  const { relation_artist_relations: artists, moretba, rawMarkdownBody } = clubNight;
  return (
    <Layout className='club-night-page'>
      <ClubNight clubNight={clubNight} />
      <div className="club-night-details">
        <h3>Artists</h3>
        <div className="club-night-artists">
          {!!rawMarkdownBody &&
            <p className='paragraph'>{rawMarkdownBody}</p>
          }
          {!!artists && !!artists.length && artists.map(artist =>
            <Artist artist={artist} />
          )}
        </div>
        {moretba &&
          <p>More TBA</p>
        }
      </div>
    </Layout>
  )
}

export default ClubNightPage;

export const pageQuery = graphql`
  query($path: String!) 
  {
    clubNight: markdownRemark(fields: { path: { eq: $path } }) 
    {
      html
      title
      date
      location
      collectionKey
      showTitle
      backgroundBig
      backgroundMedium
      moretba
      relation_artist_relations{
        title
        imageSmall
      }
      fields
      {
        path
      }
      rawMarkdownBody
    }
  }
`