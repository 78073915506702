import * as React from 'react'
import * as moment from 'moment';

import { IClubNight } from '../../typings';
import { ScrollToNext } from '../navigation/scrollToNext';
import { Artist } from './artist';

import ResponsiveSwap from '../visual/responsiveSwap';
import Parallax from '../visual/parallax';

import './clubNight.scss';

const maxArtistsToShow = 4;

interface IProps extends React.Props<HTMLElement> {
  clubNight: IClubNight;
  id?: any;
  isFeature?: boolean;
}

interface IState {
}

export const ClubNight: React.FunctionComponent<IProps> = ({ clubNight, id, isFeature }) =>{
    const { relation_artist_relations: artists, facebooklink, moretba } = clubNight;

    return (
      <div className='full-screen-section club-night' id={id} data-is-feature={!!isFeature}>

        <div className='background-image'>
          <Parallax parallaxAmount={-0.2}>
            <ResponsiveSwap
              mobileRender={
                <img src={!!clubNight.backgroundMedium ? clubNight.backgroundMedium : require('../../assets/brand/logo.jpg')} alt={clubNight.title} aria-hidden='true' />
              }
              desktopRender={
                <img src={!!clubNight.backgroundBig ? clubNight.backgroundBig : require('../../assets/brand/logo.jpg')} alt={clubNight.title} aria-hidden='true' />
              }
            />
          </Parallax>
        </div>

        <p className="club-night-date">{moment(clubNight.date).format('dddd, Do MMMM')}</p>
        <p className="club-night-location"> {clubNight.location}</p>
        
        {!!clubNight.ticketslink && !moment(clubNight.date).isBefore() &&
          <a href={clubNight.ticketslink} className="club-night-ticketslink" target='_blank' rel='noreferrer noopener'>
            <p>get tickets</p>
          </a>
        }

        {isFeature &&
          <>
            <div className="club-night-inner">
              <h3>OUR {moment(clubNight.date).isBefore() ? "LAST" : "NEXT"}<br />CLUB NIGHT</h3>
              <div className="club-night-artists">
                {!artists || !artists.length &&
                  <h3>Line up TBA</h3>
                }
                {!!artists && !!artists.length && artists.slice(0, maxArtistsToShow).map(artist =>
                  <Artist artist={artist} key={artist.title} />
                )}
              </div>
              {!!artists && !!artists.length && artists.length > maxArtistsToShow ?
                <p>+ More<br /><br /></p>
                :
                moretba &&
                <p>+ More TBA<br /><br /></p>

              }
              <a href={facebooklink} target='_blank' rel='noreferrer noopener'>
                <u><p>MORE INFO</p></u>
              </a>
            </div>
            {/* <ScrollToNext scrollToId='releases' /> */}
          </>
        }
      </div>
    )
  }