import * as React from 'react'
import { Link } from 'gatsby';

import { getLink } from '../../helpers/linkHelper';
import { IArtist } from '../../typings';

import './artist.scss';

interface IProps extends React.Props<HTMLElement> {
  artist: IArtist;
}

interface IState {
}

export class Artist extends React.Component<IProps, IState>{
  render() {
    const { artist } = this.props;
    return (
      <Link to={getLink('artist', artist.title)}>
        <div className="artist" key={artist.title}>
          <img src={artist.imageSmall} alt={artist.title} aria-hidden='true' />
          <div className="artist-hover">
            <p>{artist.title}</p>
          </div>
        </div>
      </Link>
    )
  }
}